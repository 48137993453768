import React, {
  useEffect,
  useState
} from "react"

import Layout from "../components/layout"
import { demo_solutions } from "../utils/solutions"

export default function RequestDemo({ location }) {
  const [search, setSearch] = useState("")
  useEffect(() => {
    const url = new URL(location.href)
    console.log(url.searchParams.get("solution"))

    setSearch(
      demo_solutions.find(u => u.slug === url.searchParams.get("solution"))
        ?.value
    )
  }, [location.href])
  console.log(search)
  return (
    <Layout tcoreinsight={false}>
      <div className="container mx-auto md:px-10 lg:px-16 md:flex">
        <div className="hidden md:block md:w-1/2 max-w-md text-pgray py-20">
          <h1 className="font-bold leading-tight text-4xl">
            Get a free demo of Touchcore’s automation software
          </h1>
          <h3 className="font-bold text-xl mt-5 mb-3">
            Questions? Want to see a live demo or receive a free trial?
          </h3>
          <p className="text-sm">
            Seeing is believing! Schedule a live demo with one of our product
            specialists at your convenience. We can learn about your
            requirements, answer questions, and review ways Touchcore can help
            you and your organization. Please submit your information and we
            will be in touch shortly to set up a live demo.
          </p>
        </div>
        <div className="w-9/12 mx-auto mt-12 mb-3 md:hidden text-center text-pgray">
          <h1 className="mb-4 font-bold leading-tight text-xl ">
            Have questions? Want to see a live demo or receive a free trial?
          </h1>
          <p>
            Seeing is believing! Schedule a live demo with one of our product
            specialists at your convenience and we will be in touch shortly to
            set up a live demo.
          </p>
        </div>
        <div className="md:ml-auto">
          <div className="shadow-md bg-white px-5 md:px-10  py-8">
            <form
              action="https://touchcore.us17.list-manage.com/subscribe/post"
              method="POST"
            >
              <input type="hidden" name="u" value="ac0a245d515c7b432c9d8d6f5" />
              <input type="hidden" name="id" value="86a635759d" />

              <InputField name="MERGE1" label="First Name" />
              <InputField name="MERGE2" label="Last Name" />
              <InputField name="MERGE0" label="Email" />
              <InputField name="MERGE4" label="Phone No" />
              <InputField name="MERGE8" label="Company Name" />
              <div className="flex flex-col">
                <label className="text-pgray2" for="MERGE6">
                  Select product <span class="text-pred text-xs">*</span>
                </label>
                <select
                  className="h-10 rounded-md bg-white border border-pred px-3"
                  id="MERGE6"
                  name="MERGE6"
                >
                  <option value="All Solutions">All Solutions</option>
                  {demo_solutions.map(ds => (
                    <option selected={search === ds.value} value={ds.value}>
                      {ds.value}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col mt-3 mb-5">
                <label className="text-pgray2" for="MERGE6">
                  Additional information related to your business needs{" "}
                  <span class="text-pred text-xs">*</span>
                </label>
                <textarea
                  className="border border-pred p-3 rounded-md"
                  rows={4}
                />
              </div>
              <button className="py-3 bg-pred rounded-sm block w-full text-white font-semibold">
                REQUEST DEMO
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export function InputField({ label, name, type = "text" }) {
  return (
    <div className="flex flex-col mb-3">
      <label className="text-pgray2" for={name}>
        {label}
        <span class="text-pred text-xs">*</span>
      </label>
      <input
        className="border rounded-md border-pred h-10 px-3"
        required
        name={name}
        id={name}
        type={type}
      />
    </div>
  )
}
