export const demo_solutions = [
  { value: "Travelpro - Travel Agency Management", slug: "travel-pro" },

  { value: "Tstream - Live Steaming Solution", slug: "tstream" },

  { value: "RealEstatePro - Real Estate Management", slug: "real-estate-pro" },

  { value: "ProQure - Procurement Management", slug: "proqure" },

  { value: "HospitalPro - Hospital Management", slug: "hospital-pro" },

  { value: "LegalPro - Law office Management", slug: "legal-pro" },

  { value: "Core CRM - Customer Relationship Management", slug: "core-crm" },

  { value: "CorePeople - Human Resources Management", slug: "core-people" },

  { value: "LoanX - Loan Management", slug: "loan-x" },
]
